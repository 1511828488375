import './App.css';

function App() {
  return (
    <div className="App">
     <h1>Coming soon.</h1>
	 <h2>Test</h2>
    </div>
  );
}

export default App;
